.button-container {
    display: flex; 
    justify-content: center;
    align-items: center;
    gap: 5rem; 
    margin-left: auto;
    margin-right: auto;
}

.submit-container {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    margin-bottom: 1rem;
}

.submit-button {
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
}