.next-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    gap: 6px;
    transition: transform 0.2s ease; 
}

.next-container:hover {
    transform: translateX(5px); 
}

.next-text {
    font-family: 'Urbanist';
    color: #35C69B;
    font-size: 20px;
}

.next-icon {
    height: 24px;
    width: 24px;
}

.vibrate {
    animation: vibrate 0.4s linear;
}

@keyframes vibrate {
    0% { transform: translateX(0); }
    20% { transform: translateX(-5px); }
    40% { transform: translateX(5px); }
    60% { transform: translateX(-5px); }
    80% { transform: translateX(5px); }
    100% { transform: translateX(0); }
}