.page-title {
    text-align: center;
    color: #0042c6;
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 2rem;
}

.page-container {
    width: 100%;
}