.long-container {
    display: flex;
    flex-direction: column; 
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 1.5rem; 
    max-width: 600px;
}

.long-label {
    color: #0042c6;
    font-size: 1rem;
    margin-bottom: 0;
}

.long-textarea {
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    border-color: #0042c6;
    color: #00297c;
    font-size: 1rem;
    line-height: 1.5;
    box-shadow: 4px 4px 4px rgba(62, 181, 255, 0.3);
    padding: 10px 10px;
    width: 100%;
    resize: none;
    height: auto;
}

.invalid-border {
    border-color: #D03027;
}