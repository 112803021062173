.button {
    border: none;
    border-radius: 7.5px;
    font-size: 1.5rem;
    cursor: pointer;
    padding-right: 3rem;
    padding-left: 3rem;
    padding-top: 0;
    padding-bottom: 0;
    height: 3rem;
    font-family: 'Urbanist';
}

.button.primary {
    background-color: rgb(16, 49, 151);
    color: white;
}

.button.secondary {
    background-color: rgba(0, 0, 0, 0);
    border: solid;
    border-color: white(16, 49, 151);
    color: white;
}

.button.tertiary {
    background-color: #FFAB00;
    color: white;
}

.button.error {
    background-color: #dc2222;
    color: white;
}

.button:hover {
    opacity: 0.9;
}