.question-container {
    display: grid;
    grid-template-columns: 30% 70%;
    column-gap: 2rem; 
    margin-bottom: 1.5rem; 
    align-items: center;
}

@media (max-width: 600px) {
    .question-container {
        grid-template-columns: 1fr; /* Single-column layout */
        row-gap: 0.2rem; 
        margin-bottom: 0.5rem;
        width: 100%;
    }

    .question-label {
        text-align: left !important;
    }
}

.question-label {
    color: #0042c6;
    text-align: right; 
    font-size: 1.2rem;
    margin-bottom: 0;
}

.question-input::placeholder {
    color: rgb(167, 184, 212);
}

.question-input {
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    border-color: #0042c6;
    color: #00297c;
    width: 100%; 
    height: 2rem;
    font-size: 1.2rem;
    box-shadow: 4px 4px 4px rgba(62, 181, 255, 0.3);
    padding: 10px 10px;
}

.invalid-border {
    border-color: #D03027;
}