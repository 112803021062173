/* Imports */
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css";


#hero-bg-1 {
    transform: translate(calc(1px * var(--x)), calc(1px * var(--y)));
}

#hero-bg-2 {
    transform: translate(calc(2px * var(--x)), calc(2px * var(--y)));
}

#hero-bg-3 {
    transform: translate(calc(3px * var(--x)), calc(3px * var(--y)));
}

#hero-bg-4 {
    transform: translate(calc(4px * var(--x)), calc(4px * var(--y)));
}

#hero-bg-5 {
    transform: translate(calc(5px * var(--x)), calc(5px * var(--y)));
}

#hero-bg-6 {
    transform: translate(calc(6px * var(--x)), calc(6px * var(--y)));
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(720deg);
    }
}

@keyframes grow {
    0% {
        transform: scale(1) rotate(37deg);
    }

    50% {
        transform: scale(1.3) rotate(96deg);
    }

    100% {
        transform: scale(1) rotate(37deg);
    }
}

.hero-bg-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: -1;
}

.hero-bg {
    background-color: #0042c6;
    width: 100vw;
    height: 100vh;
}

/* .hero-bg div {
    transition: all 0.5s ease-out;
} */

.hero-bg div img {
    position: absolute;
}

#hero-bg-blob {
    top: 45vh;
    left: -5vw;
    width: 600px;
}

#hero-bg-plus {
    animation: spin 2.5s ease-in-out infinite reverse;
}

#hero-bg-blob-circle {
    animation: spin 10s linear infinite;
}

#hero-bg-asterisk {
    animation: grow 5s ease-in-out infinite;
}

.hero {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-text {
    font-size: 4rem;
    color: white;
    text-align: center;
    font-family: 'Urbanist';
}

@media only screen and (max-width: 1026px) {
    .hero-bg-hidden {
        display: none;
    }

    #hero-bg-blob {
        top: 45vh;
        left: -40vw;
    }
}

@media only screen and (max-width: 600px) {
    #hero-bg-blob {
        top: 60vh;
    }
}