.navbar {
    position: absolute;
    top: 0;
    z-index: 50;
    margin: 0 auto;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: #0042c6;
}

.nav-buttons {
    display: flex;
    gap: 2.5rem;
}

.nav-button {
    position: relative;
    color: white;
    font-size: 1.2rem;
    text-decoration: none; /* Ensures there's no default underline */
    overflow: hidden;
    cursor: pointer;
}

.nav-button::after {
    content: ''; /* Necessary for pseudo-elements */
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px; /* Thickness of the line */
    background: white; /* Color of the line */
    transform: translateX(-101%); /* Start from the left, off-screen; 101 fixes the left dot issue*/
    transition: transform 0.3s ease; /* Smooth slide effect */
}

.nav-button:hover,
.nav-button:focus {
    color: white;
}


.nav-button:hover::after,
.nav-button:focus::after {
    transform: translateX(0); /* Slide into view */
}