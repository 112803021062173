.status-container {
    border: white;
    border-style: solid;
    border-width: 1px;
    margin-top: 1rem;
    padding: 2rem;
    width: fit-content;
    max-width: 90vw;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.2);
}

.status-title {
    margin-top: 5rem;
    font-size: 3rem;
    font-family: 'Urbanist';
    text-align: center;
    color: white;
}

.checkin-button {
	margin-top: 1.5rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

@media (max-width: 600px) { /* Adjust the max-width to your desired breakpoint */
    .status-container {
        margin-top: 0.5rem;
    }

    .status-title {
        margin-top: 12rem;
    }
}

.status-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    column-gap: 8rem;
}

.status-label {
    font-weight: bold;
    font-size: 1rem;
    color: #c0daff;
}

.status-value {
    font-size: 1.25rem;
    color: white
}

.confetti {
    position: absolute;
    top: 0;
    z-index: 9999;
}

@media only screen and (max-width: 768px) {
    .status-container {
        border: none;
    }

    .status-grid {
        column-gap: 3rem;
    }
}

.qr-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.qr-modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
